.search-detail-form-v1 {

	text-align: left;

	label {
		font-size: var(--font-size-md);
		text-transform: uppercase;
	}

	.card-body {
		padding: 0.5rem
	}

}