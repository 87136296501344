.unit-highlights-v2 {
	h1 {
		/*color: var(--color-secondary);*/
	}

	.location {
		color: var(--color-grey-dark);
		font-size: var(--font-size-md);

		.fa {
			margin-right: 8px;
		}
	}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}


	.facts {
		.tick-li {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;

			li {
				width: 33%;

				@media (max-width:992px) {
					width: 50%;
				}

				@media (max-width:480px) {
					width: 100%;
				}

				.fa {
					width: 30px;

				}

				.fa-stack {
					margin-left: -5px;
					width: 30px;
				}
			}
		}
	}
}