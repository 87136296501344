.search-unit-v3 {

	.srow {
		position: relative;
		cursor: pointer;

		@media (min-width: 993px) {
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			flex-direction: row;
			flex-wrap: wrap;
			margin-left: 15px;
			padding: 0;
			box-shadow: 0 0 5px rgba(0, 0, 0, .18);
			border-radius: 5px;
			margin-bottom: 35px;

			border: 2px solid #d6d6d6;
		}
	}

	.info-col {
		padding: 10px 15px;

		@media (min-width: 993px) {
			width: 50%;
			position: relative;
		}

		@media (max-width:992px) {
			margin: 0 10px;
			padding: 15px;
			position: relative;
			background-color: #fff;
			background-color: var(--white);
			top: -35px;
			border-radius: 5px;
			z-index: 5;
			box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
		}
	}

	.img-col {
		@media (min-width: 993px) {
			-webkit-box-flex: 1;
			flex: 1 1 0;
			min-width: 0;
			min-height: 0;
			position: relative;
		}

		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
		border-radius: 5px;

		overflow: hidden;
		position: relative;
		max-height: 270px;

		.owl-carousel {
			overflow: hidden;
			height: 100%;

			.item {
				@media (min-width: 993px) {
					height: 270px;
				}
			}
		}
	}

	.unit-item {


		&:hover {

			.owl-carousel {
				.owl-nav {
					button {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.go-to-unit {
				opacity: 1;
			}
		}

		.owl-carousel {
			overflow: hidden;

			.owl-nav {
				button {
					width: 30px;
					height: 30px;
					top: 107px;
					position: absolute;
					border-radius: 50%;
					cursor: pointer;
					visibility: hidden;
					opacity: 0;
					transition: visibility 0s, opacity 0.5s linear;
					color: var(--color-black);
					font-size: 40px;
					line-height: 1px;
					text-align: center;
					background-color: rgba(255, 255, 255, 0.7);
				}

				.owl-prev {
					left: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-right: 1px;
					}
				}

				.owl-next {
					right: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-left: 1px;
					}
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}
		}

		.unit-container {

			.img-col {


				.fav-icon {
					position: absolute;
					cursor: pointer;
					right: 7px;
					top: 5px;
					color: #fff;
					margin: 3px;
					font-size: 18px;
					width: 39px;
					text-align: center;
					height: 33px;
					line-height: 31px;
					z-index: 1;

					@media (min-width:993px) {
						background-color: rgba(0, 0, 0, .3);
						border-radius: 5px;
					}
				}

				.rating {
					position: absolute;
					right: 15px;
					bottom: 10px;
					padding: 7px 7px 7px 12px;
					z-index: 2;
					background-color: rgba(255, 255, 255, 0.7);
					font-size: var(--font-size-md);
					border-radius: 5px;

					@media (max-width:992px) {
						right: 15px;
						bottom: 50px;
					}

					.fa {
						color: var(--color-primary);
					}

					.ratings {
						display: inline-block;
						margin-left: 3px;
					}

				}

				.price {
					position: absolute;
					background-color: var(--color-secondary);
					text-align: center;
					top: 20px;
					right: -10px;
					padding: 0px 10px;
					z-index: 2;
					color: #fff;

					.h3 {
						font-size: 18px;
						color: #fff;
						line-height: 28px;
					}

					.main-price {
						&.h5 {
							color: var(--color-grey-light);
							text-decoration: line-through;

						}
					}


				}

				img {
					width: 100%;
					object-fit: cover;
					height: 100%;
					object-position: center center;
				}

				.img-content {

					@media (min-width: 993px) {
						position: absolute;
						width: 100%;
						height: 100%;
					}
				}

			}

			.unit-title {
				padding-top: 5px;

				.h3 {
					font-size: 18px;
					font-weight: bold;
					color: #000;
				}
			}

			.type {
				font-size: var(--font-size-md);
			}

			.place {
				font-size: var(--font-size-md);

				.fa {
					color: var(--color-grey-dark);
				}
			}

			.unit-highlights {
				position: relative;
				top: 10px;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				i[data-toggle="tooltip"] {
					padding-left: 5px;
					background-color: var(--color-grey-light);
					border-radius: 50%;
					width: 28px;
					height: 28px;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					padding: 0;
					margin-left: 5px;
				}

				.fa-stack {
					height: 33px !important;
					margin-left: -5px;
					width: 24px !important;
				}

				.svg-balcony {
					background-image: url(RESOURCE/img/antiker-balkon.svg);
					background-size: 20px 20px;
					background-repeat: no-repeat;
					background-position: center;
				}

				.svg-terrace {
					background-image: url(RESOURCE/img/terrace.svg);
					background-size: 20px 20px;
					background-repeat: no-repeat;
					background-position: center;
				}

			}

			.flex-middle {
				flex-grow: 1;
				padding: 10px 0px;
				border-bottom: 1px solid var(--color-grey-light);

				margin-bottom: 10px;
			}

			.properties-row {

				display: flex;

				.unit-properties {
					width: 50%;
					font-size: var(--font-size-md);
				}
			}

			.alternatives {
				padding-top: 20px;

				.altheading {
					font-weight: 700;
					font-size: var(--font-size-md);
				}

				a {
					font-size: var(--font-size-md);
					text-decoration: none;
					color: var(--color-secondary);

					&:hover {
						text-decoration: underline;
					}
				}

				.fa {
					font-size: var(--font-size-sm);
					color: var(--color-secondary);
				}
			}

		}

		.go-to-unit {
			height: 30px;
			display: inline-block;
			width: 30px;
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
			background-color: var(--color-primary);
			transition: all 0.5s;
			opacity: 0;

			.fa {
				color: var(--color-white);
				font-size: 20px;
				line-height: 30px;
			}

		}

		.offer-box {
			position: relative;
			margin-top: 10px;

			background-color: var(--color-primary);
			text-align: center;
			padding: 10px;
			border: 3px dashed #fff;
			font-weight: 700;

			.offer-left {
				color: var(--color-white);
			}
		}
	}

}

.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 100%;
	max-width: 520px;

	.popover-header {
		border-radius: 0;
		background-color: var(--color-grey-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
	}

	.popover-body {
		padding: 10px;


		ul {
			overflow-y: scroll;
			height: 180px;
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 6px;
					border-radius: 50%;

				}
			}
		}
	}
}