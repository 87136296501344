.search-offer-info-v1 {

	padding: 10px;
	background-color: var(--color-white);
	margin-bottom: 1.5rem;

	.offer-legende {
		font-weight: normal;
	}

	.offer-value {
		font-weight: bold;
	}

	.nowrap {
		white-space: nowrap;
	}

}