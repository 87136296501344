.search-pagination {
	margin: 30px 0 50px;

	@media (max-width: 992px) {
		margin: 5px 0 20px;
	}

	li {
		display: inline-block;
		vertical-align: middle;

		a {
			background-color: var(--color-white);
			border-radius: 3px;
			color: var(--color-primary);
			width: 40px;
			height: 40px;
			display: block;
			margin-right: 10px;
			text-align: center;
			line-height: 40px;

			&:hover {
				background-color: var(--color-primary);
				color: var(--color-white) !important;
			}
		}

		&.active {
			a {
				background-color: var(--color-primary);
				color: var(--color-white);
			}
		}
	}
}