.unit-card-v2 {

	.content {
		padding: 15px;
		position: relative;
		margin: 0 20px;
		background-color: #fff;
		background-color: var(--white);
		top: -35px;
		border-radius: 5px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);

		.place-star {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.ratings {
				display: flex;

				.fa-star,
				.fa {
					padding: 0;
					color: var(--color-primary);
					font-size: 14px;
				}
			}
		}

		.flex-align-center {
			-webkit-box-align: center;
			align-items: center;
		}

		.region-type {
			font-size: var(--font-size-md);
		}

		.fa {
			padding-right: 5px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.label-container {
			display: flex;
			justify-content: flex-end;
			flex-wrap: wrap;
			-webkit-box-align: center;
			align-items: center;
			margin: 15px -15px 0;
			padding: 10px 15px 0;
			border-top: 1px solid var(--color-grey-light);
			position: relative;

			.label {
				padding: 5px 10px;
				color: white;
				border-radius: 5px;
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);
				margin-right: 5px;
			}

			.btn {
				font-size: var(--font-size-md);
				border: none;
				color: var(--color-primary);
				font-weight: bold;
				text-transform: uppercase;
				background-color: transparent;

				&:hover {
					color: var(--color-secondary);
				}
			}

			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}


	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.rules {
		color: var(--font-color-main);

		.unit-highlights {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			i[data-toggle="tooltip"] {
				padding-left: 5px;
				background-color: var(--color-grey-light);
				border-radius: 50%;
				width: 28px;
				height: 28px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				padding: 0;
				margin-left: 5px;
			}


			/*.fa {
			background-color: var(--color-grey-light);
			border-radius: 50%;
			width: 28px;
			height: 28px;
			display: -webkit-inline-box;
			display: inline-flex;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			padding: 0;
		}*/

			.svg-balcony {
				background-image: url(RESOURCE/img/antiker-balkon.svg);
				background-size: 20px 20px;
				background-repeat: no-repeat;
				background-position: center;
			}

			.svg-terrace {
				background-image: url(RESOURCE/img/terrace.svg);
				background-size: 20px 20px;
				background-repeat: no-repeat;
				background-position: center;
			}
		}


	}

	.comodity {
		padding: 5px;

		padding-left: 2px;

	}

	.image-wrap {
		position: relative;
		border-radius: 8px;
		display: block;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
		overflow: hidden;

		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;

		}

		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}


		.favorite {
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}
}