.unit-offers-v1 {
	.unit-offer {
		padding: 30px;
		background-color: var(--color-grey-light);
		position: relative;
		margin-bottom: 30px;

		.offer-minstay {
			font-size: var(--font-size-sm);

		}

		.offer-minstay-xy {
			font-size: var(--font-size-md);

		}

		.offer-condition {
			font-size: var(--font-size-md);
		}

		.offer-banner {
			position: absolute;
			background-color: var(--color-red);
			color: var(--color-white);
			top: -13px;
			left: -8px;
			padding: 2px 10px;
			text-transform: uppercase;
			font-size: var(--font-size-sm);
			border-radius: 5px;
			box-shadow: 1px 0 3px 0 rgb(0 0 0 / 35%);

		}


	}
}